import React, { useCallback, useEffect, useState } from 'react';
import { FullPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import { Tabs, tabsConfig } from 'components/program-materials/Tabs';

import { Option } from 'common/Switch';
import { CategoriesPills } from 'components/program-materials/CategoriesPills';
import { SearchField } from 'components/program-materials/Search';
import { Filters } from 'components/program-materials/Filters';
import { MaterialsTable } from 'components/program-materials/MaterialsTable';
import { useLibraryMaterials, useUpdateMaterialCache } from 'requests/graphql/patient/queries/getLibraryMaterials';
import { useHistory } from 'react-router-dom';
import { CREATE, EDIT, LIBRARY, VIEW } from '../../constants/routes';
import { optionAll, useLibraryMaterialCategories } from '../../requests/graphql/patient/queries/getLibraryMaterialCategories';
import { useLibraryMaterialTypes } from '../../requests/graphql/patient/queries/getLibraryMaterialTypes';
import { LibraryMaterialStatuses, LibraryMaterialTypes } from '../../zeus-graphql/patient/zeus';
import { Title } from '../../components/HeaderTitle';
import { useUpdateLibraryMaterial } from '../../requests/graphql/patient/mutations/updateLibraryMaterial';
import { useDeleteLibraryMaterial } from '../../requests/graphql/patient/mutations/deleteLibraryMaterial';
import createNotification from '../../utils/createNotification';
import { ConfirmationModal } from '../../components/ui2.0/ConfirmationModal';

const itemsPerPage = 10;

export const ProgramMaterials = () => {
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [materialToDelete, setMaterialToDelete] = useState('');

  const [activeTab, setActiveTab] = useState(tabsConfig[0]);
  const [activeType, setActiveType] = useState<Option | undefined>();
  const [activeCategory, setActiveCategory] = useState<Option>(optionAll);

  const { libraryMaterialCategoriesFilterOptions, isLoading: libraryMaterialCategoriesLoading } = useLibraryMaterialCategories();
  const { libraryMaterialTypesOptions, isLoading: libraryMaterialTypesLoading } = useLibraryMaterialTypes();

  const libMaterialsArgs = {
    perPage: itemsPerPage,
    page: 1,
    filters: {
      type: activeType?.value as LibraryMaterialTypes,
      categoryIds: activeCategory?.value && activeCategory?.value !== 'all' ? [activeCategory?.value] : [],
      search,
    },
  };
  const { libraryMaterials, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useLibraryMaterials(libMaterialsArgs);

  const updateMaterialCache = useUpdateMaterialCache();

  const { updateLibrary, isLoading: isUpdateLoading } = useUpdateLibraryMaterial({});
  const { deleteMaterial, isLoading: isDeleteLoading } = useDeleteLibraryMaterial();

  const isActionLoading = isUpdateLoading || isDeleteLoading;

  const showDeleteModal = (materialId: string) => {
    setMaterialToDelete(materialId);
  };

  const resetFilter = () => {
    refetch();
  };

  const searchHandler = useCallback((value: string) => {
    setSearch(value);
    resetFilter();
  }, []);

  const typeFilterHandler = useCallback(
    (value: Option) => {
      setActiveType(value);

      if (value.value !== activeType?.value) {
        resetFilter();
      }
    },
    [activeType]
  );

  const categoryFilterHandler = useCallback(
    (value: Option) => {
      setActiveCategory(value);
      if (value.value !== activeCategory?.value) {
        resetFilter();
      }
    },
    [activeCategory]
  );

  const deleteMaterialHandler = async () => {
    if (isActionLoading) return;
    await deleteMaterial({ materialId: materialToDelete });
    createNotification({
      message: 'Material has been successfully deleted',
      type: 'success',
    });
    setMaterialToDelete('');
    refetch();
  };

  const viewMaterial = (materialId: string) => {
    history.push(`/${LIBRARY}/${materialId}/${VIEW}`);
  };
  const editMaterial = (materialId: string) => {
    history.push(`/${LIBRARY}/${materialId}/${EDIT}`);
  };

  const duplicateMaterial = (materialId: string) => {
    console.log('duplicate', materialId);
  };

  const updateMaterialStatus = useCallback(
    (materialId: string, status: LibraryMaterialStatuses) => {
      updateMaterialCache((prev) => {
        if (prev.materialId === materialId) {
          return {
            ...prev,
            status,
          };
        }
        return prev;
      });
    },
    [libraryMaterials]
  );

  const publishMaterial = async (materialId: string) => {
    if (isActionLoading) return;
    await updateLibrary({
      materialId,
      data: {
        status: LibraryMaterialStatuses.published,
      },
    });
    updateMaterialStatus(materialId, LibraryMaterialStatuses.published);
    createNotification({
      message: 'Material has been successfully published',
      type: 'success',
    });
  };
  const unpublishMaterial = async (materialId: string) => {
    if (isActionLoading) return;
    await updateLibrary({
      materialId,
      data: {
        status: LibraryMaterialStatuses.draft,
      },
    });

    updateMaterialStatus(materialId, LibraryMaterialStatuses.draft);

    createNotification({
      message: 'Material has been successfully unpublished',
      type: 'success',
    });
  };

  useEffect(() => {
    if (libraryMaterialTypesOptions.length && !activeType) {
      setActiveType(libraryMaterialTypesOptions[0]);
    }
  }, [libraryMaterialTypesOptions]);

  return (
    <FullPlate className="lg:ml-12">
      <Title pageTitle="Library" />
      <div className="header px-6 pt-6">
        <div className="flex border-b pb-6">
          <Tabs items={tabsConfig} activeItem={activeTab} onChange={setActiveTab} />
        </div>
      </div>

      <PlateContent>
        <div className="mt-[.7rem] flex justify-between">
          <CategoriesPills
            loading={libraryMaterialTypesLoading}
            items={libraryMaterialTypesOptions}
            activeItem={activeType}
            onChange={typeFilterHandler}
          />
          <button
            onClick={() => history.push(`/${LIBRARY}/${CREATE}`)}
            className="bg-main border hover:bg-independence border-main30 flex items-center text-white px-[2.7rem] h-8 rounded-[2.4rem] text-1324"
          >
            <div className="text-white">+</div> <div className="ml-[1.1rem] text-white">Material</div>
          </button>
        </div>
        <div className="flex justify-between mt-7.5 mb-4">
          <div className="search w-[33.6rem]">
            <SearchField value={search} onChange={searchHandler} />
          </div>
          <div className="filters">
            <Filters
              loading={libraryMaterialCategoriesLoading}
              items={libraryMaterialCategoriesFilterOptions}
              activeItem={activeCategory}
              onChange={categoryFilterHandler}
            />
          </div>
        </div>

        <MaterialsTable
          // canSeeStatus={can(permissions.library.canSeeStatus)}
          itemsPerPage={itemsPerPage}
          canSeeStatus={true}
          loadingMore={isFetchingNextPage}
          loadMore={fetchNextPage}
          hasMore={hasNextPage}
          materials={libraryMaterials || []}
          notFound={libraryMaterials?.length === 0 && (!!search || activeCategory?.value !== 'all')}
          onDelete={showDeleteModal}
          loading={isLoading}
          onDuplicate={duplicateMaterial}
          onView={viewMaterial}
          onEdit={editMaterial}
          onPublish={publishMaterial}
          onUnpublishMaterial={unpublishMaterial}
        />
      </PlateContent>
      {materialToDelete && (
        <ConfirmationModal
          title="Are you sure you want to delete this material?"
          okButtonClassName="bg-error"
          okText="Delete"
          onClose={() => setMaterialToDelete('')}
          onDelete={deleteMaterialHandler}
        />
      )}
    </FullPlate>
  );
};

// 1
