import React, {FC, useMemo} from 'react';
import classNames from 'clsx';

import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { ReactComponent as EyeIcon } from '../../../assets/eye.svg';
import { ReactComponent as DotsIcon } from '../../../assets/dots.svg';
import { ReactComponent as TrashIcon } from '../../../assets/trash.svg';

import UserAvatar from '../../../common/UserAvatar';
import { TreatmentPlanType } from '../../../requests/graphql/my-health/queries/getTreatmentPlan';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { DropdownMenuFunc, MenuListItem } from '../../ui2.0/DropdownMenu';
import { TreatmentPlanStatus } from '../../../zeus-graphql/my-health/zeus';

type TreatmentPlanListItemProps = {
  plan: TreatmentPlanType;
  onView: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
};

export const TreatmentPlanListItemSkeleton = () => {
  return (
    <div className="px-4 py-5 rounded-main bg-white shadow-row mb-6 animate-pulse">
      <div className="flex justify-between mb-5">
        <div className="flex">
          <div className="bg-catskillWhite w-[20rem] rounded-[3.2rem]"></div>
          <div className="bg-catskillWhite w-18  px-4 rounded-[3.2rem] ml-2"></div>
        </div>
        <div className="h-6 w-6 bg-catskillWhite rounded-full"></div>
      </div>
      <div className="pb-4 border-b mb-4">
        <div className="h-[2rem] my-2 bg-catskillWhite rounded-[3.2rem]"></div>
      </div>
      <div className="">
        <header className="flex items-center mb-2">
          <div className="w-[25%] ">
            <div className="w-[7.9rem] bg-catskillWhite rounded-[3.2rem] h-3"></div>
          </div>
          <div className="flex-1 ">
            <div className="w-[7.9rem] bg-catskillWhite rounded-[3.2rem] h-3"></div>
          </div>
          <div className="w-[7.9rem] bg-catskillWhite rounded-[3.2rem] h-3 ml-4"></div>
          <div className="w-[5.9rem] bg-catskillWhite rounded-[3.2rem] h-3 ml-4"></div>
          <div className="w-[7.4rem] bg-catskillWhite rounded-[3.2rem] h-3 ml-4"></div>
          <div className="w-[6.3rem] bg-catskillWhite rounded-[3.2rem] h-3 ml-4"></div>
        </header>
        <section className="flex items-center mt-1">
          <div className="w-[25%] flex items-center ">
            <div className="h-6 w-6 bg-catskillWhite rounded-full"></div>
            <div className="ml-2 text-1622 font-ambit font-semibold">
              <div className="w-[20.9rem] bg-catskillWhite rounded-[3.2rem] h-3"></div>
            </div>
          </div>
          <div className="flex-1">
            <div className="w-[10.9rem]"></div>
          </div>
          <div className="w-[7.9rem] ml-4 flex justify-end">
            <div className="w-[3rem] bg-catskillWhite rounded-[3.2rem] h-3"></div>
          </div>
          <div className="w-[5.9rem] ml-4 flex justify-end">
            <div className="w-[3rem] bg-catskillWhite rounded-[3.2rem] h-3"></div>
          </div>
          <div className="w-[7.4rem] ml-4 flex justify-end">
            <div className="w-[3rem] bg-catskillWhite rounded-[3.2rem] h-3"></div>
          </div>
          <div className="w-[6.3rem] ml-4 flex justify-end">
            <div className="w-[3rem] bg-catskillWhite rounded-[3.2rem] h-3"></div>
          </div>
        </section>
      </div>
    </div>
  );
};

export const TreatmentPlanListItem: FC<TreatmentPlanListItemProps> = ({ plan, onEdit, onView, onDelete, onDuplicate }) => {
  const menu = useMemo<(id: string) => MenuListItem[]>(
    () => (id: string) =>
      [
        ...(plan.status === TreatmentPlanStatus.draft
          ? [
              {
                handler: () => onEdit(id),
                icon: <EditIcon />,
                text: 'Edit',
              },
            ]
          : []),
        ...(plan.status === TreatmentPlanStatus.sent
          ? [
              {
                handler: () => onView(id),
                icon: <EyeIcon className="fill-manatee" />,
                text: 'View',
              },
            ]
          : []),
        // {
        //   handler: () => onDuplicate(id),
        //   icon: <DuplicateIcon />,
        //   text: 'Duplicate as Draft',
        // },
        ...(plan.status === TreatmentPlanStatus.draft
          ? [
              {
                handler: () => onDelete(id),
                icon: <TrashIcon className="stroke-error" />,
                text: 'Delete',
              },
            ]
          : []),
      ],
    [plan.status]
  );

  const ddMenu = DropdownMenuFunc({
    className: 'mt-2',
    placement: 'bottom-end',
    list: menu(plan.id),
    children: <DotsIcon className="cursor-pointer" />,
  });

  const handleOpenClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (ddMenu.buttonRef.current?.contains(e.target as Node) || ddMenu.elemRef.current?.contains(e.target as Node)) return;
    onView(plan.id);
  };

  return (
    <div className="px-4 py-5 rounded-main bg-white shadow-row mb-6 mx-2 border border-alabaster">
      <div className="flex justify-between mb-5">
        <div onClick={handleOpenClick} className="flex cursor-pointer">
          <div className="text-main text-1822 font-ambit font-semibold">{plan.title}</div>
          <div
            className={classNames('text-white flex items-center justify-center px-4  rounded-[3.2rem] text-1220 font-ambit font-semibold ml-2', {
              'bg-lavenderGray': plan.status === 'draft',
              'bg-dodgerBlue': plan.status === 'sent',
            })}
          >
            {capitalize(plan.status)}
          </div>
        </div>
        {ddMenu.element}
      </div>
      <div className="relative ">
        <div
          className="text-1622 font-ambit text-manatee border-b mb-4 ck-editor-view max-w-[110rem] max-h-[15rem] overflow-hidden pb-8"
          dangerouslySetInnerHTML={{ __html: plan.summary }}
        ></div>
        <div className="absolute bottom-0 bg-transWhite w-full h-8"></div>
      </div>
      <div className="">
        <header className="flex items-center">
          <div className="w-[25%] text-1220 text-manatee font-poppins uppercase ">Health Coach</div>
          <div className="flex-1 text-1220 text-manatee font-poppins uppercase ">Last Updated</div>
          <div className="w-[7.9rem] text-1220 text-manatee font-poppins uppercase text-right">Modules</div>
          <div className="w-[5.9rem] text-1220 text-manatee font-poppins uppercase text-right">Task</div>
          <div className="w-[7.4rem] text-1220 text-manatee font-poppins uppercase text-right">Surveys</div>
          <div className="w-[6.3rem] text-1220 text-manatee font-poppins uppercase text-right">Goals</div>
        </header>
        <section className="flex items-center mt-1">
          <div className="w-[25%] flex items-center ">
            <UserAvatar
              size={24}
              avatar={plan.createdBy?.profile.avatarUrl}
              firstName={plan.createdBy?.profile.fName}
              lastName={plan.createdBy?.profile.lName}
            />
            <div className="ml-2 text-1622 font-ambit font-semibold">
              {plan.createdBy?.profile.fName} {plan.createdBy?.profile.lName}
            </div>
          </div>
          <div className="flex-1 text-1622 font-ambit font-semibold">{moment(plan.createdAt).format('MM.DD.YYYY')}</div>
          <div className="w-[7.9rem] text-1622 font-ambit font-semibold text-right">-</div>
          <div className="w-[5.9rem] text-1622 font-ambit font-semibold text-right">{plan.taskAmount}</div>
          <div className="w-[7.4rem] text-1622 font-ambit font-semibold text-right">-</div>
          <div className="w-[6.3rem] text-1622 font-ambit font-semibold text-right">-</div>
        </section>
      </div>
    </div>
  );
};
