import React, {FC, useState} from 'react';
import classNames from 'clsx';

import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { LibraryMaterialType, useLibraryMaterials } from '../../../../../requests/graphql/patient/queries/getLibraryMaterials';
import { Dropdown } from '../../../../ui2.0/Dropdown';

type LibraryArticleSearchProps = {
  onSelect: (material: LibraryMaterialType) => void;
  onDelete: () => void;
};

export const LibraryArticleSearch: FC<LibraryArticleSearchProps> = ({ onSelect, onDelete }) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { libraryMaterials, isLoading } = useLibraryMaterials({ filters: { search } }, true);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="w-full">
      <Dropdown
        rootClassName="mt-2 z-30"
        button={
          <div className="flex justify-between items-center relative">
            <input
              onClick={() => setIsOpen(true)}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              type="text"
              className="py-2 rounded-md border-2 border-catskillWhite w-full px-2 text-main text-1522 font-ambit font-semibold"
            />
            <div onClick={onDelete} className="cursor-pointer absolute right-2">
              <TrashIcon className="stroke-manatee" />
            </div>
          </div>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <ul className="w-full min-w-[50rem] bg-white z-30 overflow-auto max-h-[40rem]">
          {isLoading &&
            Array(6)
              .fill(0)
              .map((_, index) => (
                <li
                  key={index + 'material'}
                  className={classNames('cursor-pointer animate-pulse w-full flex items-center px-4 py-1 h-[3.1rem] hover:bg-catskillWhite', {
                    'border-t': index > 0,
                  })}
                >
                  <div className="bg-catskillWhite w-[60%] h-3"></div>
                  <div className="bg-catskillWhite w-[10%] h-3 ml-4"></div>
                </li>
              ))}
          {!isLoading &&
            libraryMaterials?.map((material, mi) => (
              <li
                key={material.id}
                onClick={() => onSelect(material)}
                className={classNames('cursor-pointer w-full flex items-center px-4 py-1 hover:bg-catskillWhite', {
                  'border-t': mi > 0,
                })}
              >
                <div className="text-main text-1522 font-ambit font-semibold">{material.title}</div>
                <div className="text-1020 font-semibold text-independence ml-4">{material.category.title}</div>
              </li>
            ))}
        </ul>
      </Dropdown>
    </div>
  );
};
