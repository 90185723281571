import React from 'react'

import { useProgramForm, useSetProgramForm } from 'hooks/usePrograms'

import { BoldBigText } from 'common/texts'
import InputWithLabel from 'common/InputWithLabel'
import CustomSelect from 'common/CustomSelect'
import ProgramFeatures from '../../../containers/programs/ProgramFeatures'
import { useFetchOptions } from '../../../requests/programs'
import { FlexLine } from '../../../common/plate/styles'
import ProgramVisits from './ProgramVisits'

export default function ProgramForm ({ validate }) {
  const fetchOptions = useFetchOptions();

  React.useEffect(() => {
    fetchOptions();
  }, [])

  const months = [
    {
      label: '3 months',
      value: 3
    },
    {
      label: '6 months',
      value: 6
    },
    {
      label: '9 months',
      value: 9
    },
    {
      label: '12 months',
      value: 12
    },
  ]

  const billedVals = [
    {
      label: 'One Time',
      value: 'one-time'
    },
    {
      label: 'Monthly',
      value: 'monthly'
    },
  ]

  const name = useProgramForm(current => current.name || '', [])
  const description = useProgramForm(current => current.description || '', [])
  const price = useProgramForm(current => current.price || '', [])
  const discount = useProgramForm(current => current.discount || '', [])
  const paypal_id = useProgramForm(current => current.paypal_id || '', [])
  const months_of_care = useProgramForm(current => current.months_of_care || '', [])
  const billed = useProgramForm(current => current.billed || '', [])

  const setName = useSetProgramForm((prev, next) => ({ ...prev, name: next }), [])
  const setDescription = useSetProgramForm((prev, next) => ({ ...prev, description: next }), [])
  const setPrice = useSetProgramForm((prev, next) => {
    const validNextValue = next >= 0 ? next : prev.price;
    return { ...prev, price: validNextValue };
  }, []);
  const setDiscount = useSetProgramForm((prev, next) => {
    const validNextValue = next >= 0 ? next : prev.discount;
    return { ...prev, discount: validNextValue };
  }, []);
  const setPaypal = useSetProgramForm((prev, next) => ({ ...prev, paypal_id: next }), [])
  const setMonths = useSetProgramForm((prev, next) => ({ ...prev, months_of_care: next }), [])
  const setBilled = useSetProgramForm((prev, next) => ({ ...prev, billed: next }), [])

  return (
    <div className='g-mt-15'>
      <BoldBigText>
        Program information
      </BoldBigText>
      <FlexLine>
        <InputWithLabel
          className='g-mt-20 g-mr-25'
          label='Program name'
          placeholder='Enter name'
          isRequred
          isValid={!validate || name}
          value={name}
          changeHandler={setName}
        />
        <InputWithLabel
          className='g-mt-20 g-mr-25'
          label='Program Description'
          placeholder='Enter Description'
          isRequred
          isValid={!validate || description}
          value={description}
          changeHandler={setDescription}
        />
      </FlexLine>
      <FlexLine>
        <CustomSelect
          className='g-mt-20 g-mr-25'
          label='Duration'
          placeholder='Duration'
          isRequred
          isValid={!validate || months_of_care}
          value={months_of_care}
          options={months || []}
          changeHandler={setMonths}
        />
        <CustomSelect
          className='g-mt-20 g-mr-25'
          label='Billed'
          placeholder='Billed'
          isRequred
          isValid={!validate || billedVals}
          value={billed}
          options={billedVals || []}
          changeHandler={setBilled}
        />
      </FlexLine>
      <FlexLine>
        <InputWithLabel
          className='g-mt-20 g-mr-25'
          label='Price'
          type={'number'}
          placeholder='Enter price'
          min={0}
          isRequred
          isValid={!validate || price}
          value={price}
          changeHandler={setPrice}
        />
        <InputWithLabel
          className='g-mt-20 g-mr-25'
          label='Discount'
          type={'number'}
          placeholder='Enter price'
          isRequred
          isValid={!validate || discount}
          value={discount}
          changeHandler={setDiscount}
        />
      </FlexLine>
      <InputWithLabel
        className='g-mt-20 g-mr-25'
        label='Paypal ID'
        placeholder='Enter paypal'
        isRequred
        isValid={!validate || paypal_id}
        value={paypal_id}
        changeHandler={setPaypal}
      />

      <ProgramVisits />

      <ProgramFeatures />

    </div>
  )
}
