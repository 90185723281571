import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import get from 'lodash/get';

import { PATIENTS, DETAILS, OVERVIEW, COMPANIES } from 'constants/routes';

import { MediumText, HintText, RegularText } from 'common/texts';
import LinkButton from 'common/LinkButton';

export default function PatientPreview({ patient }) {
  const { companyId } = useParams();
  const history = useHistory();
  const patientId = get(patient.patient, 'id');
  const fullName = get(patient.patient, 'fullName');
  const gender = get(patient.patient, 'gender');
  const status = get(patient, 'status');
  const medicalRecord = get(patient.patient, 'medical_record');

  const navigateToPreview = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${OVERVIEW}`,
      state: { referrer: `/${COMPANIES}/${companyId}` },
    });
  }, [history, patientId, companyId]);

  return (
    <StyledWrapper>
      {/*<UserAvatar avatar={avatar} firstName={firstName} lastName={lastName} onClick={navigateToPreview} />*/}

      <div className="name">
        <MediumText>{fullName}</MediumText>
        <HintText>patient</HintText>
      </div>

      <div className="status">
        <RegularText>Status</RegularText>
        <HintText>{status}</HintText>
      </div>

      <div className="gender">
        <RegularText>Gender</RegularText>
        <HintText>{gender}</HintText>
      </div>

      <div className="mrn">
        <RegularText>MRN</RegularText>
        <HintText>{medicalRecord}</HintText>
      </div>

      <LinkButton className="view" onClick={navigateToPreview}>
        View
      </LinkButton>
      {/*{can(permissions.companies.canDeletePatient) && <DeletePatientButton patinetId={patinetId} />}*/}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafbfd;
  border: 1px solid #e9ebef;
  padding: 2rem;
  margin-top: 1.5rem;
  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
  .name {
    width: 35%;
    margin-left: 1.5rem;
    @media only screen and (max-width: 480px) {
      width: calc(100% - 5.9rem);
    }
  }
  .status {
    width: 15%;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 33%;
    }
  }
  .gender {
    width: 15%;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 33%;
    }
  }
  .mrn {
    width: 15%;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 33%;
    }
  }
  .view {
    flex-shrink: 0;
    padding: 0.6rem 3.5rem;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      width: 100%; // 48%
    }
  }
  .delete {
    flex-shrink: 0;
    padding: 0.6rem 3.5rem;
    margin-left: 3.6rem;
    @media only screen and (max-width: 480px) {
      margin-top: 1rem;
      margin-left: 0;
      width: 48%;
    }
  }
`;
