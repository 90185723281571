import React from 'react';

import { UppercaseText } from 'common/texts';
import CustomCheckbox from 'common/CustomCheckbox';

type QualificationsListProps = {
  isLoading: boolean;
  qualifications: any[];
  qualificationIds: number[];
  setQualificationIds: (a: any) => any;
};

export default function QualificationsList({ isLoading, qualifications, qualificationIds, setQualificationIds }: QualificationsListProps) {
  const parsedQualificationsList = React.useMemo(() => {
    if (!qualifications) return null;
    return qualifications.map(({ value, label, type }) => {
      return (
        <CustomCheckbox
          className="checkbox"
          key={value}
          label={label}
          checked={qualificationIds.includes(+value)}
          changeHandler={() => setQualificationIds(+value)}
        />
      );
    });
  }, [qualifications, qualificationIds, setQualificationIds]);

  return (
    <div className="g-mt-25">
      <UppercaseText className="g-mb-10">Your qualification</UppercaseText>

      {!isLoading && parsedQualificationsList}
    </div>
  );
}
