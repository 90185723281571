import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Modal from 'common/Modal'
import InputWithLabel from 'common/InputWithLabel'
import CustomButton from 'common/CustomButton'
import request from 'utils/request'
import createNotification from 'utils/createNotification'
import { useFetchAllPatients, useFetchCompanyPatients } from '../../../../requests/companies'
import { usePatinets } from '../../../../hooks/usePatients'
function existValidation (value) {
  return !!value
}
export default function InvitePatientModal ({ closeModal }) {
  const fetchAllPatients = useFetchAllPatients()
  const patients = usePatinets();
  const fetchCompanyPatients = useFetchCompanyPatients()

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [valid, setValid] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validate, setValidate] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isRequesting, setIsRequesting] = React.useState(false);
  const [emailExist, setEmailExist] = React.useState(true);
  const [selectedPatientId, setSelectedPatientId] = React.useState(null);
  const { companyId } = useParams();

  React.useEffect(()=> {
      fetchAllPatients()
    }, [])
  useEffect(() => {
    checkEmail();
  }, [email]);

  const addPatient = React.useCallback(() => {
    setIsRequesting(true)
    if (selectedPatientId) {
      request({
        method: 'put',
        url: `/companies/${companyId}/patients/${selectedPatientId}`,
        data: {}
      })
        .then(() => {
          closeModal()
          fetchCompanyPatients()
          createNotification({ message: 'Patient successfully added', type: 'success' })
        })
        .catch((error) => {
          closeModal()
          console.log(error)
        })
    } else {
      request({
        method: 'post',
        url: `/companies/${companyId}/patients`,
        data: {
          first_name: firstName,
          last_name: lastName,
          email
        }
      })
        .then(() => {
          closeModal()
          fetchCompanyPatients()
          createNotification({ message: 'Patient successfully added', type: 'success' })
        })
        .catch((error) => {
          closeModal()
          console.log(error)
        })
    }
  }, [firstName, lastName, email, closeModal, selectedPatientId]);
  const checkEmail = () => {
    const foundPatient = patients?.find(patient => patient.email === email);

    if (foundPatient) {
      setSelectedPatientId(foundPatient.id);
      setEmailExist(true)
      setValid(true)
    } else {
      setSelectedPatientId(null);
      setEmailExist(false)
      setValid(false)
    }
  };

  return (
    <Modal
      title='Invite patient'
      closeModal={closeModal}
      minWidth={48}
      maxWidth={48}
      withSeparator
    >
      <ModalContent>
        <div className='g-d-flex flex-col g-justify-between g-mt-15'>
          <InputWithLabel
            className='g-mt-20'
            label='Email'
            placeholder='Enter Email'
            isRequred
            isValid={!validate || existValidation(email)}
            value={email}
            changeHandler={setEmail}
          />
          {!emailExist && (
            <>
              <InputWithLabel
                className='g-mt-20'
                label='First name'
                placeholder='Enter first name'
                isRequred
                isValid={!validate || existValidation(firstName)}
                value={firstName}
                changeHandler={setFirstName}
              />

              <InputWithLabel
                className='g-mt-20'
                label='Last name'
                placeholder='Enter last name'
                isRequred
                isValid={!validate || existValidation(lastName)}
                value={lastName}
                changeHandler={setLastName}
              />
            </>
          )}

        </div>
        <CustomButton
          className='g-mt-20'
          disabled={(!valid && (!email || !firstName || !lastName))}
          clickHandler={addPatient}
        >
          Invite patient
        </CustomButton>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  .input {
    max-width: 100%;
  }
  .icon-wrapper {
    flex-shrink: 0;
    width: 4.8rem;
    height: 4.8rem;
    margin-top: auto;
    cursor: pointer;
    svg {
      transition: all 0.2s;
      :hover {
        opacity: 0.5;
      }
      :active {
        opacity: 1;
      }
    }
  }
  .error {
    color: #f86060;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
`
