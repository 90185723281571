import { InfiniteData, QueryClient } from '@tanstack/react-query';
import produce, { Draft } from 'immer';

type Pagination<I, P> = {
  pageInfo: P;
  items: Array<I>;
};

export const queryClientUpdateItem =
  <T, P extends Record<string, any>, C extends Pagination<T, P>>(queryClient: QueryClient, key: string) =>
  (fn: (el: Draft<T>) => Draft<T>) => {
    queryClient.setQueriesData<InfiniteData<C>>([key], (old) => {
      return produce(old, (draft) => {
        draft?.pages.forEach((page) => {
          page.items = page.items.map((el) => fn(el));
        });
      });
    });
  };
