import React, { useEffect, useState } from 'react';

import { DETAILS, PATIENTS, TREATMENT_PLANS } from 'constants/routes';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import { TreatmentPlanList } from '../../../components/patients/treatment-plans/TreatmentPlanList';
import { useHistory, useParams } from 'react-router-dom';
import { useTreatmentPlans } from '../../../requests/graphql/my-health/queries/getTreatmentPlans';
import { TreatmentPlanType } from '../../../requests/graphql/my-health/queries/getTreatmentPlan';
import { useFetchPatinetProfile } from '../../../requests/patients';
import { ConfirmationModal } from '../../../components/ui2.0/ConfirmationModal';
import createNotification from '../../../utils/createNotification';
import { useDeleteTreatmentPlan } from '../../../requests/graphql/my-health/mutations/deleteTreatmentPlan';
import { useQueryClient } from '@tanstack/react-query';
import { GET_TREATMENT_PLANS } from '../../../requests/graphql/my-health/constants';

const itemsPerPage = 5;

export default function TreatmentPlanListContainer() {
  const history = useHistory();
  const client = useQueryClient();
  const [planToDelete, setPlanToDelete] = useState('');
  const { patientId } = useParams<{ patientId: string }>();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const { deleteTreatmentPlan, isLoading: isDeleting } = useDeleteTreatmentPlan();

  const { fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, treatmentPlans } = useTreatmentPlans({
    perPage: itemsPerPage,
    page: 1,
    filters: {
      patientId,
    },
  });

  const createPlanHandler = () => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/create`);
  };
  const handleEdit = (id: string) => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/edit/${id}`);
  };
  const handleDelete = (id: string) => {
    setPlanToDelete(id);
  };
  const handleDuplicate = (id: string) => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/edit/${id}`);
  };
  const handleView = (id: string) => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/view/${id}`);
  };

  const deletePlanHandler = async () => {
    await deleteTreatmentPlan({ treatmentPlanId: planToDelete });
    setPlanToDelete('');
    await client.refetchQueries({
      predicate(query) {
        return query.queryKey[0] === GET_TREATMENT_PLANS;
      },
    });
    createNotification({
      message: 'Treatment plan has been successfully deleted',
      type: 'success',
    });
  };

  useEffect(() => {
    fetchPatinetProfile();
  }, []);

  return (
    <DetailsTemplate currentTab={TREATMENT_PLANS}>
      <div className="">
        <div className="flex justify-between mb-10">
          <div className="text-2020 font-heptaSlab font-semibold text-main">Documents List</div>
          <div className="">
            <div onClick={createPlanHandler} className="bg-main py-2 px-8 cursor-pointer rounded-[2.4rem] flex">
              <div className="text-white text-1822 mr-2">+</div>
              <div className="font-ambit text-white text-1420">ADD NEW</div>
            </div>
          </div>
        </div>
        <TreatmentPlanList
          onView={handleView}
          itemsPerPage={5}
          loadingMore={isFetchingNextPage}
          loadMore={fetchNextPage}
          hasMore={!!hasNextPage}
          loading={isLoading || isDeleting}
          onDelete={handleDelete}
          onDuplicate={handleDuplicate}
          onEdit={handleEdit}
          treatmentPlans={(treatmentPlans as TreatmentPlanType[]) || []}
        />
        {planToDelete && (
          <ConfirmationModal
            okButtonClassName="bg-error"
            okText="Delete"
            title="Are you sure you want to delete this plan?"
            onClose={() => setPlanToDelete('')}
            onDelete={deletePlanHandler}
          />
        )}
      </div>
    </DetailsTemplate>
  );
}
