import { useMemo } from 'react';
import {  GraphQLTypes, InputType, Selector } from 'zeus-graphql/patient/zeus';
import { sortOptions } from '../../../../utils/sortOptions';
import { useQuery } from '../client';
import { GET_LIBRARY_MATERIAL_CATEGORIES } from '../constants';

export const optionAll = {
  value: 'all',
  label: 'All',
};

const category = Selector('LibraryMaterialCategoryType')({
  id: true,
  title: true,
  slug: true,
  __typename: true,
});

export type LibraryMaterialCategoryType = InputType<GraphQLTypes['LibraryMaterialCategoryType'], typeof category>;

export const useLibraryMaterialCategories = () => {
  const { data, isLoading, error } = useQuery(
    [GET_LIBRARY_MATERIAL_CATEGORIES],
    {
      getLibraryMaterialCategories: category,
    },
    {
      operationName: GET_LIBRARY_MATERIAL_CATEGORIES,
    }
  );

  const libraryMaterialCategories = data?.getLibraryMaterialCategories || [];
  const libraryMaterialCategoriesOptions = useMemo(
    () =>
      libraryMaterialCategories
        .map((category) => ({
          value: category.id,
          label: category.title,
        }))
        .sort(sortOptions),
    [libraryMaterialCategories]
  );

  const libraryMaterialCategoriesFilterOptions = useMemo(
    () => [{ value: 'all', label: 'All' }, ...libraryMaterialCategoriesOptions],
    [libraryMaterialCategoriesOptions]
  );

  return {
    libraryMaterialCategories,
    libraryMaterialCategoriesOptions,
    libraryMaterialCategoriesFilterOptions,
    isLoading,
    error,
  };
};
