import { useMemo } from 'react';
import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { $, GraphQLTypes, InputType, Selector } from 'zeus-graphql/my-health/zeus';
import { GQLQuery } from '../client';
import { GET_TREATMENT_PLANS } from '../constants';
import { queryClientUpdateItem } from '../../../../utils/queryClientUpdateItem';

type GetTreatmentPlansProps = {
  perPage?: number;
  page?: number;
  filters?: {
    patientId: string;
  };
};

const provider = Selector('ProviderType')({
  __typename: true,
  email: true,
  profile: {
    avatar: true,
    lName: true,
    avatarUrl: true,
    fName: true,
  },
});

const treatmentPlan = Selector('TreatmentPlanType')({
  __typename: true,
  id: true,
  title: true,
  createdBy: provider,
  taskAmount: true,
  createdAt: true,
  summary: true,
  status: true,
  sentAt: true,
});

const treatmentPlanConnection = Selector('TreatmentPlanTypeConnection')({
  pageInfo: {
    hasNextPage: true,
    hasPreviousPage: true,
    total: true,
    perPage: true,
    currentPage: true,
    lastPage: true,
    __typename: true,
  },
  items: treatmentPlan,
});

export type ProviderType = InputType<GraphQLTypes['ProviderType'], typeof provider>;
export type TreatmentPlanType = InputType<GraphQLTypes['TreatmentPlanType'], typeof treatmentPlan>;
export type TreatmentPlanTypeConnection = InputType<GraphQLTypes['TreatmentPlanTypeConnection'], typeof treatmentPlanConnection>;

export const getTreatmentPlans = async (variables: GetTreatmentPlansProps = {}) => {
  const { perPage = 10, page = 1, filters = {} } = variables;
  const result = await GQLQuery(
    {
      getTreatmentPlans: [
        {
          perPage: $`perPage`,
          page: $`page`,
          filters: $`filters`,
        },
        treatmentPlanConnection,
      ],
    },
    {
      operationName: GET_TREATMENT_PLANS,
      variables: {
        perPage,
        page,
        filters,
      },
    }
  );

  return result.getTreatmentPlans;
};

export const useUpdateTreatmentPlanCache = () => {
  const queryClient = useQueryClient();
  return queryClientUpdateItem<TreatmentPlanType, GraphQLTypes['PageInfo'], TreatmentPlanTypeConnection>(queryClient, GET_TREATMENT_PLANS);
};

export const useTreatmentPlans = (variables: GetTreatmentPlansProps = {}) => {
  const { perPage = 10, page = 1, filters = {} } = variables;

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    [GET_TREATMENT_PLANS, JSON.stringify(variables)],
    (data) =>
      getTreatmentPlans({
        filters: filters as GetTreatmentPlansProps['filters'],
        page: data.pageParam || page,
        perPage,
      }),
    {
      cacheTime: 60000,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pageInfo.currentPage < lastPage.pageInfo.lastPage) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );

  const treatmentPlans = useMemo(
    () =>
      data?.pages?.reduce((acc: TreatmentPlanType[], page: InfiniteData<TreatmentPlanTypeConnection>['pages']['0']) => {
        return [...acc, ...page.items];
      }, []),
    [data?.pageParams]
  );

  return {
    treatmentPlans,
    isLoading,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    hasNextPage,
  };
};
