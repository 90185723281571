import React from 'react'
import { useParams } from 'react-router-dom'
import { RegularText } from 'common/texts'
import Modal from 'common/Modal'
import CustomButton from 'common/CustomButton'

import request from 'utils/request'
import { useFetchPatinetSummary } from 'requests/patients'

export default function UpdateMembershipModal ({ closeModal, id, name, }) {
  const fetchPatinetSummary = useFetchPatinetSummary()

  const [state, setState] = React.useState(1)

  let [modalTitle, setModalTitle] = React.useState(null)

  const [disabled, setDisabled] = React.useState(false)

  // eslint-disable-next-line default-case
  switch (state) {
    // eslint-disable-next-line no-lone-blocks
    case 1: {modalTitle = 'You may transition this patient to the T2D Maintenance program. Press Continue to proceed.'} break
    // eslint-disable-next-line no-lone-blocks
    case 2: {modalTitle = 'Once you transition the patient to the T2D Maintenance program, their unused visits will not be summarised with the Maintenance program. The action can not be reverted. Press Update to confirm the action.'} break
    // eslint-disable-next-line no-lone-blocks
    case 3: {modalTitle = 'The package has been updated. Available services will be updated in the patient profile, patient will be notified about this via email.' } break
  }

  const successButton = state === 1 ? 'Continue' :  state === 2 ? 'Update' : 'Close';

  const { patientId } = useParams()

  const updateMembership =  React.useCallback(async () => {
    setDisabled(true)
    let data = null
    try {
      data = await request({
        method: 'put',
        url: `/providers/membership/program`,
        data: {
          patientId: patientId,
        }
      })
    } catch (error) {
      // createNotification({ message: 'Membership has expired!', type: 'error' })
      setState(4)
      setModalTitle(error.response.data.message)
    }
    if (200 === data?.status) {
      // createNotification({ message: 'Membership has been successfully updated!', type: 'success' })
      setState(3)
    }
    setDisabled(false)

  }, [id, closeModal, fetchPatinetSummary])

  return (
    <Modal
      title='Change Package'
      closeModal={() => { fetchPatinetSummary(); closeModal() }}
      minWidth={50}
      maxWidth={50}
      withSeparator
    >
      <RegularText className='g-mt-15' >
        {modalTitle}
      </RegularText>
      <div className='g-d-flex g-justify-between g-align-center g-mt-15'>
        <CustomButton
          clickHandler={() => {state === 1 ? setState(2) : state === 2 ? updateMembership() : (() => {fetchPatinetSummary(); closeModal()})() }}
          disabled={disabled}
        >
          {successButton}
        </CustomButton>
        {(state !== 3) && (state !== 4) ? (
          <CustomButton
          clickHandler={closeModal}
        >
          Cancel
        </CustomButton>
        ) : ''}

      </div>
    </Modal>
  )
}