import defaultTheme from 'tailwindcss/defaultTheme';
import { DefaultTheme } from 'tailwindcss/types/generated/default-theme';
import theme from '../tailwind.theme.json';

export type TailwindCustomTheme = DefaultTheme & typeof theme.extend;
export type TailwindCustomColors =  typeof theme.extend.colors;

export const useTailwindTheme = () => ({ ...defaultTheme, ...theme.extend });

export const useTailwindColors = () => ({ ...defaultTheme.colors, ...theme.extend.colors } as TailwindCustomColors);

export default useTailwindTheme;
