import React from 'react'

import { PROGRAMS } from 'constants/routes'

import { useSetProgramForm } from 'hooks/usePrograms'

import { WideRowPlate } from 'common/plate/plates'
import { PlateHeader, PlateContent } from 'common/plate/styles'
import BackButton from 'common/plate/BackButton'
import ProgramForm from 'components/programs/create-program/ProgramForm'
import CreateButton from '../../components/programs/create-program/CreateButton'

export default function CreateProgram () {
  const clearProgramForm = useSetProgramForm(() => ({}), [])
  const [validate] = React.useState(false)

  React.useEffect(() => {
    return clearProgramForm
  }, [clearProgramForm])

  return (
    <WideRowPlate>
      <PlateHeader>
        <BackButton href={`/${PROGRAMS}`} />
        Add new Program
      </PlateHeader>

      <PlateContent>
        <ProgramForm validate={validate} />
        <CreateButton />
      </PlateContent>
    </WideRowPlate>
  )
}
