import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { COMPANIES } from 'constants/routes';

import { MediumText, RegularText, HintText } from 'common/texts'
import UserAvatar from 'common/UserAvatar'
import LinkButton from 'common/LinkButton'
import { useSetCompanyPatients } from '../../../hooks/useCompanies'

export default function CompanyPreview ({ company }) {
  const history = useHistory()
  const id = get(company, 'id')
  const avatar = get(company, 'logo_url')
  const name = get(company, 'name')
  const count = get(company, 'patients_count')

  const clearPatinets = useSetCompanyPatients(() => null, [])

  const navigateToPreview = React.useCallback(() => {
    history.push(`/${COMPANIES}/${id}`)
  }, [history, id])

  return (
    <StyledWrapper
      /*onMouseEnter={prefetch}
      onFocus={prefetch}*/
      onMouseOut={clearPatinets}
    >
      <UserAvatar
        avatar={avatar}
        firstName={name}
      />

      <MediumText className='name'>
        {name}
      </MediumText>

      <div className='count'>
        <RegularText>
          Patients
        </RegularText>
        <HintText>
          {count}
        </HintText>
      </div>

      <LinkButton
        className='view'
        onClick={navigateToPreview}
      >
        View
      </LinkButton>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fafbfd;
  border: 1px solid #e9ebef;
  padding: 2rem;
  margin-top: 1.5rem;
  .name {
    max-width: 100%;
    margin-left: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .count {
    min-width: 8rem;
    margin-left: auto;
  }
  .view {
    margin-left: 2rem;
    padding: 0.6rem 3.5rem;
  }
`
