import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { COMPANIES } from 'constants/routes'
import { useCompany, useSetCompanyPatients } from 'hooks/useCompanies'
import { UppercaseText, RegularText } from 'common/texts';
import { PlateHeader } from 'common/plate/styles';
import BackButton from 'common/plate/BackButton';
import InvitePatientButton from './patients-loader/InvitePatientButton'
import { useUserContext } from '../../../context/userContext'

export default function CompanyHeader() {
  const name = useCompany((current) => get(current, 'name', ''), []);
  const count = useCompany((current) => get(current, 'patients_count', 0), []);

  const clearPatinets = useSetCompanyPatients(() => null, [])
  const { role } = useUserContext()

  return (
    <StyledPlateHeader>
      <BackButton href={`/${COMPANIES}`} onClick={clearPatinets}/>
      <StyledSpan className="company-name">{name}</StyledSpan>
      <div className="counter">
        <UppercaseText className="title">patients</UppercaseText>
        <RegularText>{`${count}`}</RegularText>
      </div>
      {'admin' === role && (
        <div>
          <InvitePatientButton />
        </div>
      )}
    </StyledPlateHeader>
  );
}

const StyledSpan = styled.span`
margin-right: auto`

const StyledPlateHeader = styled(PlateHeader)`
  padding: 1.8rem 2.5rem;
  .coupon {
    max-width: 40%;
    font-weight: 400;
    margin-left: auto;
    padding-left: 0.5rem;
    .title {
      margin-bottom: 0.6rem;
    }
    p {
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .counter {
    font-weight: 400;
    margin-left: 4rem;
    .title {
      margin-bottom: 0.6rem;
    }
    p {
      line-height: 1;
    }
  }
`;
