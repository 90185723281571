import React, { FC } from 'react';

import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { LIBRARY, VIEW } from '../../../../../constants/routes';

type LibraryArticleProps = {
  title: string;
  materialId: string;
  category: string;
  isPreview?: boolean;
  onDelete?: () => void;
};

export const LibraryArticle: FC<LibraryArticleProps> = ({ title, materialId, category, isPreview, onDelete }) => {
  return (
    <div className="flex justify-between items-center bg-catskillWhite py-2 px-3 rounded-md my-2">
      <a
        target="_blank"
        href={`/${LIBRARY}/${materialId}/${VIEW}`}
        className="flex items-center cursor-pointer"
        rel="noreferrer"
      >
        <div className="text-main text-1522 font-ambit font-semibold">{title}</div>
        <div className="text-1020 font-semibold text-independence ml-2">{category}</div>
      </a>
      {!isPreview && (
        <div onClick={onDelete} className="cursor-pointer">
          <TrashIcon className="stroke-manatee" />
        </div>
      )}
    </div>
  );
};
