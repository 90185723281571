import React, { useState, useCallback } from 'react';
import get from 'lodash/get';

import CustomButton from 'common/CustomButton';

import createNotification from 'utils/createNotification';

import useTranslations from 'translations';
import { useHistory } from 'react-router-dom';
import { Cognito } from 'libs/cognito';
import { emailRegExp } from './../../../utils/regexp';
import { LOGIN } from 'constants/routes';

const cognito = Cognito.getInstance();

export default React.memo(function SubmitButton({ email, disabled }) {
  const history = useHistory();
  const translations = useTranslations();
  const submitButton = get(translations, 'forgotPassword.submitButton');

  const [isLoading, setIsLoading] = useState(false);

  const resetAttempt = useCallback(() => {
    const normalizedEmail = email.trim().toLocaleLowerCase(); //lowered and trimmed
    const isEmailValid = emailRegExp.test(normalizedEmail);

    if (!isEmailValid) {
      createNotification({ message: 'Invalid email', type: 'error' });
      return;
    }

    setIsLoading(true);

    cognito
      .sendForgotPasswordCode(normalizedEmail)
      .then(({ data }) => {
        createNotification({
          message: 'Temporary password has been sent to your email',
          type: 'success',
        });
        history.push(`/${LOGIN}`);

        const startTime = Date.now();
        localStorage.setItem('resetStartTime', startTime.toString());
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false); 
      });
  }, [email]);

  return (
    <CustomButton 
      className="g-mb-15 g-w-100" 
      clickHandler={resetAttempt} 
      disabled={disabled || isLoading}
    >
      {submitButton}
    </CustomButton>
  );
});
