import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import qs from 'qs';

import { APPOINTMENTS, APPOINTMENTS_ADMIN, MONTH, RESCHEDULE, RESCHEDULE_SUCCESS } from 'constants/routes';
import { PERIOD_CONFIG } from 'constants/dates-options';

import { globalContext } from 'hooks/useGlobalState';

import {
  useSetBookedAppointments,
  useSetBookedAppointment,
  useSetScheduledAppointments,
  useSetAppointments,
  useSetAppointment,
  useSetAppointmentsProviders,
} from 'hooks/useAppointments';

import request from 'utils/request';

import createNotification from 'utils/createNotification';
import { useUserContext } from '../context/userContext';

export function getAppointmentsParams({ period, provider, patient }) {
  const params = {
    type: 'all',
    date: PERIOD_CONFIG[period],
    orderBy: 'started_at',
    sortedBy: 'desc',
  };
  if (provider) params.provider = provider;
  if (patient) params.patient = patient;
  return qs.stringify(params, { encode: false });
}

export function useFetchBookedAppointments() {
  const { user } = React.useContext(globalContext);
  const setBookedAppointments = useSetBookedAppointments((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      const id = get(user, 'value.id');
      request({
        method: 'get',
        url: `/appointments`,
        params: {
          provider: id,
          orderBy: 'started_at',
          sortedBy: 'asc',
          ...params,
        },
      })
        .then(
          ({
            data: {
              data: { appointments },
            },
          }) => setBookedAppointments(appointments)
        )
        .catch((error) => console.log(error));
    },
    [user, setBookedAppointments]
  );
}

export function useFetchAppointments() {
  const setAppointments = useSetAppointments((prev, next) => next, []);
  return React.useCallback(
    ({ period, provider, patient }) => {
      request({
        method: 'get',
        url: `/appointments?${getAppointmentsParams({ period, provider, patient })}`,
      })
        .then(
          ({
            data: {
              data: { appointments },
            },
          }) => setAppointments(appointments)
        )
        .catch((error) => console.log(error));
    },
    [setAppointments]
  );
}

export function useFetchAppointment() {
  const { appointmentId } = useParams();
  const history = useHistory();
  const setAppointment = useSetAppointment((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/appointments/${appointmentId}`,
    })
      .then(({ data }) => setAppointment(data))
      .catch((error) => {
        console.log(error);
        history.pushState(`/${APPOINTMENTS}`);
      });
  }, [appointmentId, setAppointment, history]);
}

export function useFetchAppointmentsProviders() {
  const setAppointmentsProviders = useSetAppointmentsProviders((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/providers`,
    })
      .then(
        ({
          data: {
            data: { providers },
          },
        }) => setAppointmentsProviders(providers)
      )
      .catch((error) => console.log(error));
  }, [setAppointmentsProviders]);
}

export function useFetchBookedAppointment() {
  const { appointmentId } = useParams();
  const { isAdmin } = useUserContext();
  const history = useHistory();
  const setBookedAppointment = useSetBookedAppointment((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      request({
        method: 'get',
        url: `/appointments/${appointmentId}`,
      })
        .then(({ data }) => setBookedAppointment(data))
        .catch((error) => {
          console.log(error);
          history.push(isAdmin ? `/${APPOINTMENTS_ADMIN}` : `/${APPOINTMENTS}/${MONTH}`);
        });
    },
    [appointmentId, history, setBookedAppointment]
  );
}

export function useFetchScheduledAppointments() {
  const { user } = React.useContext(globalContext);
  const setScheduledAppointments = useSetScheduledAppointments((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      const id = get(user, 'value.id');
      request({
        method: 'get',
        url: `/providers/${id}/schedule`,
        params,
      })
        .then(({ data: { data } }) => setScheduledAppointments(data))
        .catch((error) => console.log(error));
    },
    [user, setScheduledAppointments]
  );
}

export function useCreateAppointment() {
  const history = useHistory();
  const { user, appointmentCreate } = React.useContext(globalContext);
  const { isAdmin } = useUserContext();

  return React.useCallback(
    (setLoader) => {
      const type = get(user, 'value.qualification_type');
      const appointmentCreateSnap = appointmentCreate.value;
      setLoader(true);
      return request({
        method: 'post',
        url: `/appointments`,
        data: {
          patient: appointmentCreateSnap.patient.id,
          provider: appointmentCreateSnap.provider.id,
          date: appointmentCreateSnap.selectedDate,
          reason: appointmentCreateSnap.reason,
          type: type,
          qualification: appointmentCreateSnap.qualification,
          agreement_policy: true,
          agreement_cancel: true,
        },
      })
        .then(() => {
          createNotification({ message: 'Appointment successfully planned', type: 'success' });
          history.push(`/${isAdmin ? APPOINTMENTS_ADMIN : APPOINTMENTS}`);
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    },
    [user, history, appointmentCreate]
  );
}

export function useChangeAppointment() {
  const history = useHistory();
  const { appointment } = React.useContext(globalContext);
  return React.useCallback(
    (setIsSended) => {
      const appointmentSnap = appointment.value;
      const appointmentId = get(appointmentSnap, 'id');
      const providerId = get(appointmentSnap, 'provider.id');
      const startedAt = get(appointmentSnap, 'started_at');
      const rescheduleReason = get(appointmentSnap, 'changeReason');
      setIsSended(true);
      request({
        method: 'put',
        url: `/admins/appointments/${appointmentId}`,
        data: {
          provider: providerId,
          started_at: startedAt,
          reschedule_reason: rescheduleReason,
        },
      })
        .then(() => {
          history.push(`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/${RESCHEDULE_SUCCESS}`);
        })
        .catch((error) => {
          console.log(error);
          setIsSended(false);
        });
    },
    [appointment, history]
  );
}
export function useDownloadFile() {
  const { appointmentsFilters } = React.useContext(globalContext);
  return React.useCallback(
    (type) => {
      const { period, provider, patient } = appointmentsFilters.value;
      request({
        method: 'get',
        url: `/appointments/download/${type}?${getAppointmentsParams({ period, provider, patient })}`,
        responseType: 'blob',
      })
        .then((response) => {
          const { data } = response;
          const contentDisposition = response.request.getResponseHeader('Content-Disposition');
          const name = contentDisposition.split('filename')[1].replace(/"/g, '').replace(/=/g, '');
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log(error));
    },
    [appointmentsFilters]
  );
}
