import React, { FC, useCallback, useEffect, useState } from 'react';

import {object, array, string, number} from 'yup';

import { ReactComponent as DietIcon } from 'assets/diet.svg';

import { SectionHeader } from '../../SectionHeader';

import { SectionFooter } from '../../SectionFooter';
import { DietEditItem } from './DietSectionEditItem';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';
import { AddSectionButton } from '../../AddSectionButton';
import { validate } from 'utils/extractErrors';
import debounce from 'lodash/debounce';

type DietEditProps = {
  tasks?: TaskType<'diet'>[];
  onCancel: () => void;
  onDelete: () => void;
  onDeleteSection: (index: number) => void;
  onSave: (val: TaskType<'diet'>[]) => void;
  onAddNew?: () => void;
};

const schema = array().of(
  object().shape({
    summary: string().required('Summary is required'),
    duration: number().required('Duration is required').positive('Duration cannot be zero').max(999, 'Duration cannot be more than 999 days'),
    description: string().required('Description is required'),
  })
);

export const DietEdit: FC<DietEditProps> = ({ tasks, onAddNew, onCancel, onSave, onDelete, onDeleteSection }) => {
  const [currentTasks, setCurrentTasks] = useState(tasks);
  const [errors, setErrors] = useState<Record<string, any>[]>([]);

  const handleChangeTask = useCallback(
    (task, id) => {
      const newTasks = currentTasks?.map((d, i) => (i === id ? task : d)) || [];
      if (errors.length) {
        validate(
          schema,
          newTasks.map((t) => t.details),
          setErrors,
          () => setErrors([])
        );
      }
      setCurrentTasks(newTasks);
    },
    [currentTasks, errors]
  );

  const handleSubmit = (submit: () => void) => () => {
    validate(schema, currentTasks?.map((t) => t.details) || [], setErrors, submit);
  };

  const errorsMessages = (taskIndex: number) => ({
    description: errors?.[taskIndex]?.description,
    duration: errors?.[taskIndex]?.duration,
    summary: errors?.[taskIndex]?.summary,
  });

  const save = useCallback(() => {
    onSave(currentTasks || []);
  }, [currentTasks]);

  useEffect(() => {
    setCurrentTasks(tasks);
  }, [tasks]);

  return (
    <div className="mb-6">
      <div className="shadow-row p-6 border rounded-main">
        <div className="pb-6">
          <SectionHeader title="Diet" icon={<DietIcon />} isEditing={true} onDelete={onDelete} />
        </div>
        {currentTasks?.map((task, taskIndex) => (
          <DietEditItem
            hasDeleteSection={taskIndex > 0}
            onDeleteSection={() => onDeleteSection(taskIndex)}
            errors={errorsMessages(taskIndex)}
            key={task.id || 'diet' + taskIndex}
            task={task}
            onChange={debounce((task) => handleChangeTask(task, taskIndex), 400)}
          />
        ))}
        <SectionFooter onCancel={onCancel} onSave={handleSubmit(save)} />
      </div>
      {onAddNew && <AddSectionButton onClick={onAddNew} />}
    </div>
  );
};
