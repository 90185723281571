import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'clsx';

import { ReactComponent as TrashIcon } from 'assets/trash.svg';

import { CKEditor } from 'common/CKEditor';

import { InputField } from 'components/ui2.0/InputField';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import { LibraryArticleList } from '../../LibraryArticle/LibraryArticleList';
import { TreatmentPlanFiles } from '../../../Files/TreatmentPlanFiles';
import { DnDFiles } from 'components/ui2.0/DnDFiles';
import { FilesResult, useAttachFiles } from 'hooks/useAttachFiles';
import createNotification from 'utils/createNotification';
import { DatePicker } from 'components/ui2.0/DatePicker';
import moment from 'moment';
import { SelectOption } from 'utils/sortOptions';
import { SelectField } from '../../../../../ui2.0/SelectField';
import { findOption } from './findOption';

type MedicationsEditProps = {
  task: TaskType<'medications'>;
  hasDeleteSection?: boolean;
  medicationMeasuresOptions: SelectOption[];
  medicationCategoriesOptions: SelectOption[];
  medicationFrequenciesOptions: SelectOption[];
  errors: Partial<Record<keyof TaskType<'medications'>['details'] | 'startDate' | 'endDate', string>>;
  onChange: (task: TaskType<'medications'>) => void;
  onDeleteSection: () => void;
};

export const MedicationsEditItem: FC<MedicationsEditProps> = ({
  task,
  errors,
  onChange,
  onDeleteSection,
  hasDeleteSection,
  medicationMeasuresOptions,
  medicationCategoriesOptions,
  medicationFrequenciesOptions,
}) => {
  const [currentTask, setCurrentTask] = useState(task);

  const handleAddFile = useCallback((files: FilesResult) => {
    const newFiles = files.uploaded.map((file) => ({
      id: file.id || '',
      fileName: file.originalFileName || '',
      fileType: file.fileType || 'application/pdf',
    }));

    if (files.failed.length > 0) {
      files.failed.forEach((file) => {
        createNotification({ message: `${file.originalFileName} - ${file.message}`, type: 'error' });
      });
    }

    setCurrentTask((p) => ({ ...p, files: [...(p.files || []), ...newFiles] }));
  }, []);

  const { onAttachFiles, uploadingFiles } = useAttachFiles({
    onSuccess: handleAddFile,
  });

  const handleDeleteFile = useCallback((id) => {
    setCurrentTask((p) => ({ ...p, files: p.files?.filter((file) => file.id !== id) }));
  }, []);

  const handleAddMaterial = useCallback((material: TaskType['materials']['0']) => {
    setCurrentTask((p) => ({ ...p, materials: [...(p.materials || []).filter((m) => m.id !== material.id), material] }));
  }, []);

  const handleDeleteMaterial = useCallback((id) => {
    setCurrentTask((p) => ({ ...p, materials: p.materials?.filter((material) => material.id !== id) }));
  }, []);

  const handleChangeOptionVal = useCallback(
    (name: 'categoryId' | 'measureId' | 'frequencyId') => (option: SelectOption) => {
      setCurrentTask((p) => {
        return { ...p, details: { ...p.details, [name]: Number(option.value) } };
      });
    },
    []
  );

  const handleChangeStringVal = useCallback(
    (name: 'quantity' | 'description' | 'name') => (val: string | number) => {
      let value = val as string | number;

      if (name === 'quantity') {
        value = Number(val);
      }
      setCurrentTask((p) => ({ ...p, details: { ...p.details, [name]: value } }));
    },
    []
  );

  const handleChangeStartDate = useCallback((value: Date | null) => {
    setCurrentTask((p) => {
      const startDateMoment = moment(value);
      const endDateMoment = moment(p.endDate);
      let endDate = endDateMoment.toISOString();
      if (startDateMoment.isAfter(endDateMoment)) {
        endDate = '';
      }
      return { ...p, startDate: startDateMoment.toISOString(), endDate };
    });
  }, []);

  const handleChangeEndDate = useCallback((value: Date | null) => {
    setCurrentTask((p) => ({ ...p, endDate: moment(value).toISOString() }));
  }, []);

  const selectedCategory = useMemo(findOption(medicationCategoriesOptions, currentTask.details.categoryId as number), [
    medicationCategoriesOptions,
    currentTask.details.categoryId,
  ]);

  const selectedMeasure = useMemo(findOption(medicationMeasuresOptions, currentTask.details.measureId as number), [
    medicationMeasuresOptions,
    currentTask.details.measureId,
  ]);

  const selectedFrequency = useMemo(findOption(medicationFrequenciesOptions, currentTask.details.frequencyId as number), [
    medicationFrequenciesOptions,
    currentTask.details.frequencyId,
  ]);

  useEffect(() => {
    if (JSON.stringify(currentTask) !== JSON.stringify(task)) {
      onChange(currentTask as TaskType<'medications'>);
    }
  }, [JSON.stringify(currentTask), JSON.stringify(task)]);

  return (
    <DnDFiles onFilesAdded={onAttachFiles}>
      {(isDragActive) => (
        <div className="relative">
          <div
            className={classNames('text-brightTurquoise text-2020 font-ambit font-semibold top-[50%] left-[50%] -mt-8 -ml-[7rem] absolute z-10', {
              flex: isDragActive,
              hidden: !isDragActive,
            })}
          >
            Drop Files Here
          </div>
          <div
            className={classNames('border-2 border-brightTurquoise rounded-main p-4 mb-6', {
              'border-dashed': isDragActive,
            })}
          >
            <div className={classNames('w-full', { 'opacity-30': isDragActive })}>
              {hasDeleteSection && (
                <div className="flex justify-end">
                  <div
                    onClick={onDeleteSection}
                    className="ml-3 cursor-pointer h-6 w-6 rounded-full bg-catskillWhite flex justify-center items-center"
                  >
                    <TrashIcon className="stroke-manatee" />
                  </div>
                </div>
              )}
              <div className="flex justify-between mb-4">
                <div className="w-[50%] mr-2.5">
                  <InputField label="Name" isRequired value={currentTask.details.name} error={errors.name} onChange={handleChangeStringVal('name')} />
                </div>
                <div className="flex justify-between w-[50%]">
                  <div className="w-full mr-2.5">
                    <DatePicker
                      error={errors.startDate}
                      value={currentTask.startDate}
                      label="Start date"
                      minDate={new Date()}
                      isRequired
                      onChange={handleChangeStartDate}
                    />
                  </div>
                  <div className="w-full">
                    <DatePicker
                      error={errors.endDate}
                      value={currentTask.endDate}
                      label="End date"
                      minDate={currentTask.startDate ? new Date(currentTask.startDate) : new Date()}
                      isRequired
                      onChange={handleChangeEndDate}
                    />
                  </div>
                </div>
              </div>
              {/* -------------------------- */}
              <div className="flex justify-between mb-4">
                <div className="flex justify-between w-[50%] mr-2.5">
                  <div className="w-full mr-2.5">
                    <SelectField
                      label="Medication"
                      isRequired
                      placeholder=" "
                      selected={selectedCategory}
                      error={errors.categoryId}
                      onSelect={handleChangeOptionVal('categoryId')}
                      options={medicationCategoriesOptions}
                    />
                  </div>
                  <div className="w-full">
                    <SelectField
                      label="Measure"
                      isRequired
                      placeholder=" "
                      selected={selectedMeasure}
                      error={errors.measureId}
                      onSelect={handleChangeOptionVal('measureId')}
                      options={medicationMeasuresOptions}
                    />
                  </div>
                </div>
                <div className="flex justify-between w-[50%]">
                  <div className="w-[50%] mr-2.5">
                    <InputField
                      type="number"
                      error={errors.quantity}
                      value={currentTask.details.quantity + ''}
                      label="Quantity"
                      isRequired
                      onChange={handleChangeStringVal('quantity')}
                    />
                  </div>
                  <div className="w-[50%]">
                    <SelectField
                      label="Frequency"
                      isRequired
                      placeholder=" "
                      selected={selectedFrequency}
                      error={errors.frequencyId}
                      onSelect={handleChangeOptionVal('frequencyId')}
                      options={medicationFrequenciesOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="ck-short-editor mb-6">
                <CKEditor
                  error={errors.description}
                  label="Notes"
                  data={currentTask.details.description}
                  onChange={handleChangeStringVal('description')}
                />
              </div>
              <LibraryArticleList
                color="text-brightTurquoise"
                onAdd={handleAddMaterial}
                materials={currentTask.materials}
                onDelete={handleDeleteMaterial}
              />
            </div>
          </div>
          <TreatmentPlanFiles uploadingFiles={uploadingFiles} onAttach={onAttachFiles} files={currentTask.files} onDelete={handleDeleteFile} />
        </div>
      )}
    </DnDFiles>
  );
};
