import React from 'react';

import EditAppointmentModal from './edit-modal';

export default function EditButton({ id, status }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  if (status === 'CANCELLED') return null;

  return (
    <>
      <div
        className="hover:bg-white py-1 cursor-pointer transition-all outline-none leading-[2.4rem] flex justify-center bg-[#f9fafc] border rounded-[2.4rem] text-[1.3rem] px-4 border-[#e9ebef] text-[#0000007f] md:mr-4"
        onClick={openModal}
      >
        Edit
      </div>

      {isOpen && <EditAppointmentModal closeModal={closeModal} id={id} status={status} />}
    </>
  );
}
