import React, { useState, useEffect } from 'react';
import { LOGIN } from 'constants/routes';
import AuthTemplate from 'components/auth/AuthTemplate';
import AuthLink from 'components/auth/AuthLink';
import AuthInput from 'components/auth/AuthInput';
import { RegularText } from 'common/texts';
import SubmitButton from 'components/auth/forgot-password/SubmitButton';
import useTranslations from 'translations';

const MILLISECONDS_IN_SECOND = 1000;
const MAX_WAIT_TIME_SECONDS = 300;

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const { title, hint, emailPlaceholder, signInLink } = useTranslations().forgotPassword;

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

 const calculateRemainingTime = () => {
    const savedStartTime = localStorage.getItem('resetStartTime');

    if (savedStartTime) {
      const timeElapsed = Math.floor((Date.now() - parseInt(savedStartTime)) / MILLISECONDS_IN_SECOND);
      const remainingTime = MAX_WAIT_TIME_SECONDS - timeElapsed;

      if (remainingTime > 0) {
        setIsButtonDisabled(true);
        setTimeLeft(remainingTime);
      } else {
        localStorage.removeItem('resetStartTime');
        setIsButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    calculateRemainingTime();
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, MILLISECONDS_IN_SECOND);

      return () => clearInterval(timerId);
    } else if (isButtonDisabled) {
      setIsButtonDisabled(false);
      localStorage.removeItem('resetStartTime');
    }
  }, [timeLeft, isButtonDisabled]);

  const minutes = Math.floor(timeLeft / 60);
   const seconds = (timeLeft % 60).toString().padStart(2, '0');

  return (
    <AuthTemplate title={title}>
      <RegularText className="g-mt-40 g-mb-10">{hint}</RegularText>
       <AuthInput className="g-mb-15" value={email} changeHandler={setEmail} placeholder={emailPlaceholder} type="email" />
      {isButtonDisabled && (
        <RegularText className="g-my-10 g-mb-10">
          Not received? Please wait {minutes}:{seconds} before requesting another reset.
        </RegularText>
      )}
      <SubmitButton email={email} disabled={isButtonDisabled} />
      <AuthLink value={signInLink} href={`/${LOGIN}`} />
    </AuthTemplate>
  );
}
