import { ValidationError, AnyObject } from 'yup';

export const extractErrors = (errors: ValidationError[]) => {
  const extractedErrors: Record<string, any>[] = [];
  errors.forEach((error) => {
    const [i = '', prop = ''] = (error.path || '')?.split('.');
    if (i && prop) {
      const index = i.replace(/\[|\]/g, '');
      if (extractedErrors[Number(index)]) {
        extractedErrors[Number(index)][prop] = error.message;
      } else {
        extractedErrors[Number(index)] = { [prop]: error.message };
      }
    }
  });

  return extractedErrors;
};

/*
TODO: Fix this type
TaskType ? TaskType : TaskType['details']
*/
export const validate = <T extends any>(
  schema: AnyObject,
  data: T[],
  onFail: (errors: Record<string, string>[]) => void,
  onSuccess?: (d?: T[]) => void
) => {
  schema
    .validate(data, {
      abortEarly: false,
    })
    .then(onSuccess)
    .catch((err: ValidationError) => {
      console.log('err', err.inner);

      onFail(extractErrors(err.inner));
    });
};
