import React from 'react';

import { RegularBigText } from 'common/texts';
import InputWithLabel from 'common/InputWithLabel';
import QualificationsList from './QualificationsList';

type SpecialityProps = {
  label: string;
  title: string;
  isLoading: boolean;
  qualifications: any[];
  qualificationIds: number[];
  setQualificationIds: (a: number) => any;
  onTitleChange: (value: string) => void;
};

export default function Speciality({
  label,
  title,
  qualifications,
  qualificationIds,
  isLoading,
  onTitleChange,
  setQualificationIds,
}: SpecialityProps) {
  return (
    <div>
      <RegularBigText>{label}</RegularBigText>

      <InputWithLabel
        className="g-mt-15"
        label="Qualification title"
        placeholder="Enter your qualification title"
        isLoading={isLoading}
        isRequred
        isValid={title.trim()}
        value={title}
        changeHandler={onTitleChange}
      />

      <QualificationsList
        isLoading={isLoading}
        qualifications={qualifications}
        qualificationIds={qualificationIds}
        setQualificationIds={setQualificationIds}
      />
    </div>
  );
}
